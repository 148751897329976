import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [2,3,4];

export const dictionary = {
		"/(singed-in)/(verified)": [~6,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/analytics": [~9,[2,3]],
		"/(singed-out)/battery-guide": [29,[5]],
		"/(singed-in)/(verified)/buy-credit": [~22,[2,3]],
		"/(singed-in)/(verified)/(admin)/hub-payment/[userId]": [~7,[2,3,4]],
		"/(singed-in)/(verified)/(hub-staff)/hubs": [~10,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]": [~11,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/batteries": [~12,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/batteries/[batteryId]": [~13,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/batteries/[batteryId]/tracking": [~14,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/feedback": [~15,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/rentals": [~16,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/rentals/[rentalId]/issue": [~17,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/rentals/[rentalId]/return": [~18,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/trackers": [~19,[2,3]],
		"/(singed-in)/(verified)/(hub-staff)/hubs/[hubId]/trackers/[trackerId]": [~20,[2,3]],
		"/(singed-out)/privacy-policy": [30,[5]],
		"/(singed-in)/profile": [~26,[2]],
		"/(singed-in)/(verified)/rentals": [~23,[2,3]],
		"/(singed-in)/(verified)/rentals/[rentalId]/feedback": [~24,[2,3]],
		"/(singed-in)/(verified)/reserve-battery": [~25,[2,3]],
		"/(singed-out)/reset-password/send-code": [~32,[5]],
		"/(singed-out)/reset-password/verify-code/[phoneNumber]": [~33,[5]],
		"/(singed-out)/reset-password/[tokenId]": [~31,[5]],
		"/(singed-out)/sign-in": [~34,[5]],
		"/(singed-in)/sign-out": [~27,[2]],
		"/(singed-out)/sign-up": [~35,[5]],
		"/(singed-out)/terms-of-service": [36,[5]],
		"/(singed-in)/(verified)/(admin)/transactions": [~8,[2,3,4]],
		"/(singed-out)/user-payment/[paymentId]": [~37,[5]],
		"/(singed-in)/(verified)/(hub-staff)/users": [~21,[2,3]],
		"/(singed-in)/verify-account": [~28,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';